/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/


.css-162agw7 {display: none;}
span.css-1s7jahp {display: none;}
a.css-13dp5cj {display: none;}